import { render, staticRenderFns } from "./articleDetail.vue?vue&type=template&id=700b428a&scoped=true&"
import script from "./articleDetail.vue?vue&type=script&lang=ts&"
export * from "./articleDetail.vue?vue&type=script&lang=ts&"
import style0 from "./articleDetail.vue?vue&type=style&index=0&id=700b428a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700b428a",
  null
  
)

export default component.exports